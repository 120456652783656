import Image from 'next/image';
import { PageData } from '@interfaces';
import { formatKeywords } from '@functions';
import styles from './PageHeader.module.css';

interface PageHeaderProps {
  meta: PageData;
  baseUrl: string;
}

// Custom loader function
const customLoader = ({ src, width, quality }: { src: string; width: number; quality?: number }) => {
  return `${src}?w=${width}&q=${quality || 75}`;
};

export default function PageHeader({ meta, baseUrl }: PageHeaderProps) {
  const primaryImageData = meta.imageData;

  const normalizeUrl = (base: string, path: string | undefined): string => {
    if (!path) return base;
    return `${base.replace(/\/+$/, '')}/${path.replace(/^\/+/, '')}`.replace(/([^:])\/+/g, '$1/');
  };

  const primaryImageUrl =
    primaryImageData?.schema?.url || normalizeUrl(baseUrl, meta.thumbnail as string);
  const baseWidth = primaryImageData?.dimensions.width || 800;
  const baseHeight = primaryImageData?.dimensions.height || 600;

  const synopsis = meta.description;

  return (
    <header className={styles.pageHeader}>
      <h1 className={styles.title}>
        <a href={primaryImageUrl} rel="noopener noreferrer">
          {meta.title}
        </a>
      </h1>
      {primaryImageUrl && (
        <div className={styles.imageContainer}>
          <Image
            loader={customLoader}
            src={primaryImageUrl}
            width={baseWidth}
            height={baseHeight}
            alt={primaryImageData?.alt || `Illustration for ${meta.title}`}
            loading="lazy"
            className={styles.headerImage}
          />
        </div>
      )}
      <section className={styles.synopsis}>
        <h2>Synopsis</h2>
        <p>{synopsis}</p>
        <div className={styles.keywords}>{formatKeywords(meta.keywords_str)}</div>
      </section>
    </header>
  );
}
