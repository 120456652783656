import React, { useState, useEffect, useRef } from 'react';
import Image from 'next/image';
import styles from './pdfViewer.module.css';
import { FormatType } from '@interfaces';
import { fetchDirectoryLinks, fetchDirectoryPaths } from '@functions';
interface PdfViewerProps {
  rootPath?: string;
  rootString?: string | null;
  initialString?: string | null;
  searchQuery?: string; // New prop for search query
}

interface DirectoryEntry {
  doc_id: string;
  root_path: string;
  root_string: string;
}

const PdfViewer = ({ rootPath = 'pdfs', rootString = null, initialString = null, searchQuery = '' }: PdfViewerProps): React.ReactNode => {
  const [directories, setDirectories] = useState<string[]>([]);
  const [directoryLinks, setDirectoryLinks] = useState<string[]>([]);
  const [selectedDirIndex, setSelectedDirIndex] = useState<number>(-1);
  const [format, setFormat] = useState<any>('pdf');
  const [availableFormats] = useState<FormatType[]>(['pdf', 'pdf_pages', 'text', 'images']);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorStates, setErrorStates] = useState<{ [key: string]: boolean }>({});
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const controlsRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    async function initialize() {
      try {
        setIsLoading(true);
        let paths: string[] = [];
        let links: string[] = [];

        if (searchQuery) {
          // Fetch search results from API
          const response = await fetch(`http://localhost:3060/api/jfk_search?q=${encodeURIComponent(searchQuery)}`);
          const data = await response.json();
          if (data.result) {
            paths = data.result.map((entry: DirectoryEntry) => entry.root_path);
            links = data.result.map((entry: DirectoryEntry) => entry.root_string);
          }
        } else {
          // Fallback to original fetch logic if no search query
          const [pathsResponse, linksResponse] = await Promise.all([
            fetchDirectoryPaths(rootPath, rootString || 'mufon'),
            fetchDirectoryLinks(rootPath, rootString || 'mufon')
          ]);

          paths = Array.isArray(pathsResponse) ? pathsResponse : pathsResponse?.result || [];
          links = Array.isArray(linksResponse) ? linksResponse : linksResponse?.result || [];
        }

        if (paths.length > 0 && links.length > 0 && paths.length === links.length) {
          setDirectories(paths);
          setDirectoryLinks(links);

          if (initialString) {
            const initialLower = initialString.toLowerCase();
            const closestIndex = paths.findIndex((path: string) => {
              const baseName = getBaseName(path).toLowerCase();
              return baseName.includes(initialLower);
            });
            setSelectedDirIndex(closestIndex >= 0 ? closestIndex : 0);
          } else {
            setSelectedDirIndex(0);
          }
        } else {
          console.error('Invalid response data:', { paths, links });
        }
      } catch (error) {
        console.error('Initialization error:', error);
      } finally {
        setIsLoading(false);
      }
    }
    initialize();
  }, [rootPath, rootString, initialString, searchQuery]);

  // Rest of the PdfViewer component remains unchanged
  useEffect(() => {
    if (selectedDirIndex >= 0 && directories.length > 0) {
      checkAvailableFormatsAndPages();
    }
  }, [selectedDirIndex, directories]);

  useEffect(() => {
    setCurrentPage(1);
    if (selectedDirIndex >= 0 && format !== 'pdf') {
      checkPageCountForFormat();
    }
  }, [format, selectedDirIndex]);

  useEffect(() => {
    const updateHeight = () => {
      if (controlsRef.current && contentRef.current) {
        const controlsHeight = controlsRef.current.getBoundingClientRect().height;
        const windowHeight = window.innerHeight;
        const contentHeight = windowHeight - controlsHeight;
        contentRef.current.style.height = `${contentHeight}px`;
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [selectedDirIndex, format]);

  useEffect(() => {
    if (format === 'pdf' || selectedDirIndex < 0 || totalPages <= 1) return;

    if (format === 'text' || format === 'pdf_pages') {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        const pageHeight = iframe.contentWindow.document.body.scrollHeight / totalPages;
        iframe.contentWindow.scrollTo(0, (currentPage - 1) * pageHeight);
      }
    } else if (format === 'images') {
      const container = imageContainerRef.current;
      if (container) {
        const pageHeight = container.scrollHeight / totalPages;
        container.scrollTo(0, (currentPage - 1) * pageHeight);
      }
    }
  }, [currentPage, format, selectedDirIndex, totalPages]);

  const checkAvailableFormatsAndPages = async (): Promise<void> => {
    if (selectedDirIndex < 0 || selectedDirIndex >= directoryLinks.length) return;
  
    setIsLoading(true);
    setTotalPages(0);
    setCurrentPage(1);
  
    for (let fmt of ['pdf', 'pdf_pages', 'text', 'images']) {
      const extension = fmt === 'text' ? 'txt' : fmt === 'images' ? 'png' : 'pdf';
      const url = get_content_link(fmt, selectedDirIndex, 1, extension);
      try {
        const response = await fetch(url);
        if (response.ok) {
          setFormat(fmt); // Update state
          if (fmt !== 'pdf') {
            // Pass fmt directly to ensure the correct format is used
            await checkPageCountForFormat(fmt);
          }
          break;
        }
      } catch (error) {
        console.log(`${fmt} not available:`, error);
      }
    }
    setIsLoading(false);
  };

// Update checkPageCountForFormat to accept fmt as a parameter
const checkPageCountForFormat = async (fmt: any = format): Promise<void> => {
  if (selectedDirIndex < 0 || selectedDirIndex >= directoryLinks.length) return;

  const extension = fmt === 'text' ? 'txt' : fmt === 'images' ? 'png' : 'pdf';
  let page = 1;
  try {
    while (true) {
      const url = get_content_link(fmt, selectedDirIndex, page, extension);
      const response = await fetch(url);
      if (!response.ok) break;
      page++;
    }
    setTotalPages(page - 1);
  } catch (error) {
    console.error(`Error counting pages for ${fmt}:`, error);
  }
};
  const getBaseName = (dir: string) => dir.split('/').pop() || dir;

  const get_content_link = (type: string, dirIndex: number, page: number, ext: string) => {
    if (dirIndex < 0 || dirIndex >= directoryLinks.length) return '';
    
    const baseName = directoryLinks[dirIndex];
    const basePath = directories[dirIndex];
    if (type === 'pdf') {
      return `${basePath}/${baseName}.pdf`;
    } else {
      return `${basePath}/${type}/${baseName}_page_${page}.${ext}`;
    }
  };

  const handleError = (key: string) => {
    setErrorStates(prev => ({ ...prev, [key]: true }));
    console.log(`Error loading content: ${key}`);
  };

  const handlePageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const page = parseInt(e.target.value, 10);
    if (!isNaN(page) && page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleScroll = () => {
    if (format === 'pdf' || selectedDirIndex < 0 || totalPages <= 1) return;

    if (format === 'text' || format === 'pdf_pages') {
      const iframe = iframeRef.current;
      if (!iframe || !iframe.contentWindow) return;

      const scrollTop = iframe.contentWindow.scrollY || 0;
      const scrollHeight = iframe.contentWindow.document.body.scrollHeight || 0;
      const pageHeight = scrollHeight / totalPages;
      const newPage = Math.max(1, Math.min(totalPages, Math.floor(scrollTop / pageHeight) + 1));

      if (newPage !== currentPage) {
        setCurrentPage(newPage);
      }
    } else if (format === 'images') {
      const container = imageContainerRef.current;
      if (!container) return;

      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const pageHeight = scrollHeight / totalPages;
      const newPage = Math.max(1, Math.min(totalPages, Math.floor(scrollTop / pageHeight) + 1));

      if (newPage !== currentPage) {
        setCurrentPage(newPage);
      }
    }
  };

  const renderContent = (): React.ReactNode => {
    if (isLoading) return <div>Loading...</div>;
    if (selectedDirIndex < 0 || directories.length === 0) return <div>No directories found</div>;

    const errorKey = `${format}-${selectedDirIndex}-${currentPage}`;
    const url = get_content_link(format, selectedDirIndex, currentPage, 
      format === 'text' ? 'txt' : format === 'images' ? 'png' : 'pdf');

    switch (format) {
      case 'pdf':
        return errorStates[errorKey] ? <div>Failed to load</div> : (
          <embed src={url} type="application/pdf" width="100%" height="100%" 
            onError={() => handleError(errorKey)} />
        );
      case 'pdf_pages':
        return errorStates[errorKey] ? <div>Failed to load</div> : (
          <iframe
            ref={iframeRef}
            src={url}
            width="100%"
            height="100%"
            title={`Page ${currentPage}`}
            style={{ overflow: 'auto', border: 'none' }}
            onLoad={() => {
              if (iframeRef.current?.contentWindow) {
                iframeRef.current.contentWindow.removeEventListener('scroll', handleScroll);
                iframeRef.current.contentWindow.addEventListener('scroll', handleScroll);
              }
            }}
            onError={() => handleError(errorKey)}
          />
        );
      case 'images':
        return errorStates[errorKey] ? <div>Failed to load image</div> : (
          <div 
            ref={imageContainerRef} 
            style={{ overflowY: 'auto', height: '100%' }}
            onScroll={handleScroll}
          >
            <Image 
              src={url} 
              alt={`Page ${currentPage}`} 
              width={800} 
              height={600} 
              style={{ maxWidth: '100%', height: 'auto' }} 
              unoptimized 
              onError={() => handleError(errorKey)} 
            />
          </div>
        );
      case 'text':
        return errorStates[errorKey] ? <div>Failed to load text</div> : (
          <iframe
            ref={iframeRef}
            src={url}
            width="100%"
            height="100%"
            title={`Page ${currentPage}`}
            style={{ overflow: 'auto', border: 'none' }}
            onLoad={() => {
              if (iframeRef.current?.contentWindow) {
                iframeRef.current.contentWindow.removeEventListener('scroll', handleScroll);
                iframeRef.current.contentWindow.addEventListener('scroll', handleScroll);
              }
            }}
            onError={() => handleError(errorKey)}
          />
        );
      default:
        return <div>No content available</div>;
    }
  };

  useEffect(() => {
    return () => {
      if (iframeRef.current?.contentWindow) {
        iframeRef.current.contentWindow.removeEventListener('scroll', handleScroll);
      }
      if (imageContainerRef.current) {
        imageContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [format, selectedDirIndex]);

  return (
    <div className={styles['pdf-viewer']} style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <div ref={controlsRef} className={styles.controls}>
        <select 
          value={selectedDirIndex} 
          onChange={e => setSelectedDirIndex(parseInt(e.target.value))}
        >
          <option value={-1}>Select a document</option>
          {directories.map((dir, index) => (
            <option key={dir} value={index}>{directoryLinks[index]}</option>
          ))}
        </select>

        {selectedDirIndex >= 0 && (
          <>
            <select value={format} onChange={e => setFormat(e.target.value as FormatType)}>
              {availableFormats.map(fmt => (
                <option key={fmt} value={fmt}>
                  {fmt === 'pdf_pages' ? 'PDF Pages' : fmt.charAt(0).toUpperCase() + fmt.slice(1)}
                </option>
              ))}
            </select>

            {format !== 'pdf' && totalPages > 0 && (
              <div className={styles.pagination}>
                <button onClick={() => setCurrentPage(p => Math.max(1, p - 1))} 
                  disabled={currentPage === 1}>Previous</button>
                <select value={currentPage} onChange={handlePageChange}>
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                    <option key={page} value={page}>Page {page}</option>
                  ))}
                </select>
                <span>of {totalPages}</span>
                <button onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))} 
                  disabled={currentPage === totalPages}>Next</button>
              </div>
            )}
          </>
        )}
      </div>
      <div ref={contentRef} className={styles.content} style={{ flex: 1, overflow: 'hidden' }}>
        {renderContent()}
      </div>
    </div>
  );
};

export default PdfViewer;