import styles from './Body.module.css';
import Sources from '@Sources';
import PdfViewer from '@pdfViewer';

// Define the PdfViewer options type
interface PdfViewerOptions {
  rootPath?: string;
  rootString?: string | null;
  initialString?: string | null;
  searchQuery?: string;
}

const Body: React.FC<{
  meta: any;
  pdfViewer?: PdfViewerOptions | boolean; // Union type: object or boolean
}> = ({
  meta,
  pdfViewer = false, // Default to false if not provided
}) => {
  // Determine if PdfViewer should render and extract props
  const shouldRenderPdfViewer = pdfViewer !== false;
  const pdfViewerProps = typeof pdfViewer === 'object' ? {
    rootPath: pdfViewer.rootPath ?? 'pdfs',
    rootString: pdfViewer.rootString ?? null,
    initialString: pdfViewer.initialString ?? null,
    searchQuery: pdfViewer.searchQuery ?? '',
  } : {};

  return (
    <main className={styles.body}>
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: meta.content || '' }} />

      {(meta.content && meta.content.includes('youtube.com/embed')) && (
        <p className={styles.note}>
          Note: YouTube videos may not load if tracking protection is enabled.
        </p>
      )}

      {shouldRenderPdfViewer && <PdfViewer {...pdfViewerProps} />}

      {meta.media && <Sources media={meta.media} />}
    </main>
  );
};

export default Body;