import React from 'react';
import styles from './Sources.module.css';
import {MediaSource} from '@interfaces';


interface SourcesProps {
  media: MediaSource[];
}

const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.webp', '.svg'];

const Sources: React.FC<SourcesProps> = ({ media }) => {
  if (!media || media.length === 0) return null;

  // Create a Set to track unique base URLs
  const seenBaseUrls = new Set<string>();

  return (
    <section className={styles.sources}>
      <h2>Sources</h2>
      <ul className={styles.sourcesList}>
        {media.map((item, index) => {
          const { url, title, description,keywords,type,filename,basename } = item;
          const fullUrl = url || '#';
          
          // Skip if URL is just '#'
          if (fullUrl === '#' || type === 'image') {
            return null;
          }

          // Check if the URL ends with any image extension
          const isImageUrl = imageExtensions.some(ext => fullUrl.toLowerCase().endsWith(ext));
          if (isImageUrl) {
            return null;
          }

          // Normalize URL by removing trailing '#' or '#something'
          const baseUrl = fullUrl.split('#')[0];
          
          // Skip if this base URL was already seen
          if (seenBaseUrls.has(baseUrl)) {
            return null;
          }
          seenBaseUrls.add(baseUrl);

          const final_title = title || filename || basename ;
          const final_description = description || keywords;

          return (
            <li key={index} className={styles.sourceItem}>
              <div className={styles.sourceTitle}>
                <a href={fullUrl} rel="noopener noreferrer" className={styles.sourceLink}>
                  {final_title}
                </a>
              </div>
              <div className={styles.sourceUrl}>
                <a href={fullUrl} rel="noopener noreferrer" className={styles.sourceLink}>
                  {fullUrl}
                </a>
              </div>
              <div className={styles.sourceDescription}>
                  {final_description}
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default Sources;